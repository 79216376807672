export const colors = {
  primary: '#2196F3',
  unselected: '#545C66',
  actIndicator: '#2e3742',
  error: 'red',
};

export const ITEMLAYOUT = 40;

export const defaultDropdownProps = {
  primaryColor: colors.primary,
  enableSearch: false,
  error: false,
  floating: false,
  required: false,
  disabled: false,
  elevation: 2,
  borderRadius: 2,
  activityIndicatorColor: colors.actIndicator,
  searchPlaceholder: 'Search',
  helperText: '',
  errorColor: colors.error,
  itemTextStyle: {},
  showLoader: false,
  animationInTiming: 400,
  animationOutTiming: 400,
  headerContainerStyle: {},
  headerTextStyle: {},
  stickySectionHeadersEnabled: true,
  parentDDContainerStyle: {},
  itemContainerStyle: {},
  rippleColor: undefined,
  disableSort: false,
  enableAvatar: false,
  avatarSize: 30,
  emptySelectionText: 'Selected items will appear here...',
  mainContainerStyle: {},
  underlineColor: undefined,
  disableSelectionTick: false,
  textInputPlaceholder: undefined,
  textInputPlaceholderColor: undefined,
  selectedItemTextStyle: {},
  selectedItemViewStyle: {},
  removeLabel: false,
};
